import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  default as darkVector,
  default as darkVectorMobile,
  default as darkVectorTablet,
} from "../../../assets/backgrounds/index-vector.png";
import { emailRegex, HUBSPOT_PROXY_URL } from "../../../constants";
import "../../../styles/components/Slices/HomepageQuiz.scss";
import CustomButton from "../../CustomButton";
import { isDomainBlocked, parseURL } from "../../../utilities/helpers";
import { NextQuestionIcon } from "../../../assets/icons/Explore";
import BlogPageQuizForm from "../../Pieces/BlogpageQuizForm";
import { BlogQuizFinal } from "../../Pieces/BlogQuizFinal";

const optionToLinkMap = {
  "Fragmented data management and curation": "/encord-index",
  "Accurately labeling large complex datasets": "/annotate",
  "Poor model performance": "/active",
  "Cannot scale AI development": "/ai-data-readiness-report",
  "Too many tools required to manage data": "/ai-data-readiness-report",
  "I am not sure how to start AI model development":
    "/ai-data-readiness-report",
};

const _slices = [
  {
    primary: {
      theme: "dark",
      question_title: {
        html: "<p>How are you developing AI?</p>",
        text: "How are you developing AI?",
        richText: [
          {
            type: "paragraph",
            text: "How are you developing AI?",
            spans: [],
            direction: "ltr",
          },
        ],
        raw: [
          {
            type: "paragraph",
            text: "How are you developing AI?",
            spans: [],
            direction: "ltr",
          },
        ],
      },
    },
    items: [
      {
        option: {
          html: "<p>Building custom AI trained on enterprise data</p>",
          text: "Building custom AI trained on enterprise data",
          richText: [
            {
              type: "paragraph",
              text: "Building custom AI trained on enterprise data",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Building custom AI trained on enterprise data",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>Fine-tuning open source AI models</p>",
          text: "Fine-tuning open source AI models",
          richText: [
            {
              type: "paragraph",
              text: "Fine-tuning open source AI models",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Fine-tuning open source AI models",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>Fine-tuning closed-source API based models</p>",
          text: "Fine-tuning closed-source API based models",
          richText: [
            {
              type: "paragraph",
              text: "Fine-tuning closed-source API based models",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Fine-tuning closed-source API based models",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>Integrating AI services through APIs</p>",
          text: "Integrating AI services through APIs",
          richText: [
            {
              type: "paragraph",
              text: "Integrating AI services through APIs",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Integrating AI services through APIs",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>I dont know how to start</p>",
          text: "I dont know how to start",
          richText: [
            {
              type: "paragraph",
              text: "I dont know how to start",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "I dont know how to start",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>Outsourcing AI development to agencies or consultants</p>",
          text: "Outsourcing AI development to agencies or consultants",
          richText: [
            {
              type: "paragraph",
              text: "Outsourcing AI development to agencies or consultants",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Outsourcing AI development to agencies or consultants",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
    ],
    id: "2",
    slice_type: "homepage_quiz",
    slice_label: null,
    __typename: "PrismicBlogDataBodyHomepageQuiz",
  },
  {
    primary: {
      theme: "dark",
      question_title: {
        html: "<p>What type of data do you work with?</p>",
        text: "What type of data do you work with?",
        richText: [
          {
            type: "paragraph",
            text: "What type of data do you work with?",
            spans: [],
            direction: "ltr",
          },
        ],
        raw: [
          {
            type: "paragraph",
            text: "What type of data do you work with?",
            spans: [],
            direction: "ltr",
          },
        ],
      },
    },
    items: [
      {
        option: {
          html: "<p>Text</p>",
          text: "Text",
          richText: [
            {
              type: "paragraph",
              text: "Text",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Text",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>PDF Documents</p>",
          text: "PDF Documents",
          richText: [
            {
              type: "paragraph",
              text: "PDF Documents",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "PDF Documents",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>Image</p>",
          text: "Image",
          richText: [
            {
              type: "paragraph",
              text: "Image",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Image",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>Audio</p>",
          text: "Audio",
          richText: [
            {
              type: "paragraph",
              text: "Audio",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Audio",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>Video</p>",
          text: "Video",
          richText: [
            {
              type: "paragraph",
              text: "Video",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Video",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>DICOM / NIfTI</p>",
          text: "DICOM / NIfTI",
          richText: [
            {
              type: "paragraph",
              text: "DICOM / NIfTI",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "DICOM / NIfTI",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
    ],
    id: "3",
    slice_type: "homepage_quiz",
    slice_label: null,
    __typename: "PrismicBlogDataBodyHomepageQuiz",
  },
  {
    primary: {
      theme: "dark",
      question_title: {
        html: "<p>How much data do you work with?</p>",
        text: "How much data do you work with?",
        richText: [
          {
            type: "paragraph",
            text: "How much data do you work with?",
            spans: [],
            direction: "ltr",
          },
        ],
        raw: [
          {
            type: "paragraph",
            text: "How much data do you work with?",
            spans: [],
            direction: "ltr",
          },
        ],
      },
    },
    items: [
      {
        option: {
          html: "<p>50,000- 1 million files</p>",
          text: "50,000- 1 million files",
          richText: [
            {
              type: "paragraph",
              text: "50,000- 1 million files",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "50,000- 1 million files",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>1 million - 100 million+ files</p>",
          text: "1 million - 100 million+ files",
          richText: [
            {
              type: "paragraph",
              text: "1 million - 100 million+ files",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "1 million - 100 million+ files",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>Over 1 billion files</p>",
          text: "Over 1 billion files",
          richText: [
            {
              type: "paragraph",
              text: "Over 1 billion files",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Over 1 billion files",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
    ],
    id: "4",
    slice_type: "homepage_quiz",
    slice_label: null,
    __typename: "PrismicBlogDataBodyHomepageQuiz",
  },
  {
    primary: {
      theme: "dark",
      question_title: {
        html: "<p>How many AI models do you have in production?</p>",
        text: "How many AI models do you have in production?",
        richText: [
          {
            type: "paragraph",
            text: "How many AI models do you have in production?",
            spans: [],
            direction: "ltr",
          },
        ],
        raw: [
          {
            type: "paragraph",
            text: "How many AI models do you have in production?",
            spans: [],
            direction: "ltr",
          },
        ],
      },
    },
    items: [
      {
        option: {
          html: "<p>None or unsure</p>",
          text: "None or unsure",
          richText: [
            {
              type: "paragraph",
              text: "None or unsure",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "None or unsure",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>1 - 5 models</p>",
          text: "1 - 5 models",
          richText: [
            {
              type: "paragraph",
              text: "1 - 5 models",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "1 - 5 models",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>&gt; 5 models</p>",
          text: "> 5 models",
          richText: [
            {
              type: "paragraph",
              text: "> 5 models",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "> 5 models",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
    ],
    id: "5",
    slice_type: "homepage_quiz",
    slice_label: null,
    __typename: "PrismicBlogDataBodyHomepageQuiz",
  },
  {
    primary: {
      theme: "dark",
      question_title: {
        html: "<p>What is your main challenge in achieving production AI?</p>",
        text: "What is your main challenge in achieving production AI?",
        richText: [
          {
            type: "paragraph",
            text: "What is your main challenge in achieving production AI?",
            spans: [],
            direction: "ltr",
          },
        ],
        raw: [
          {
            type: "paragraph",
            text: "What is your main challenge in achieving production AI?",
            spans: [],
            direction: "ltr",
          },
        ],
      },
    },
    items: [
      {
        option: {
          html: "<p>Fragmented data management and curation processes</p>",
          text: "Fragmented data management and curation processes",
          richText: [
            {
              type: "paragraph",
              text: "Fragmented data management and curation processes",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Fragmented data management and curation processes",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>Accurately labeling large complex datasets</p>",
          text: "Accurately labeling large complex datasets",
          richText: [
            {
              type: "paragraph",
              text: "Accurately labeling large complex datasets",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Accurately labeling large complex datasets",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>Poor model performance blocking AI deployments to production</p>",
          text: "Poor model performance blocking AI deployments to production",
          richText: [
            {
              type: "paragraph",
              text: "Poor model performance blocking AI deployments to production",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Poor model performance blocking AI deployments to production",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>Cannot scale AI development</p>",
          text: "Cannot scale AI development",
          richText: [
            {
              type: "paragraph",
              text: "Cannot scale AI development",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Cannot scale AI development",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>Too many tools required to manage and prepare data for AI</p>",
          text: "Too many tools required to manage and prepare data for AI",
          richText: [
            {
              type: "paragraph",
              text: "Too many tools required to manage and prepare data for AI",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "Too many tools required to manage and prepare data for AI",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
      {
        option: {
          html: "<p>I am not sure how to start AI model development</p>",
          text: "I am not sure how to start AI model development",
          richText: [
            {
              type: "paragraph",
              text: "I am not sure how to start AI model development",
              spans: [],
              direction: "ltr",
            },
          ],
          raw: [
            {
              type: "paragraph",
              text: "I am not sure how to start AI model development",
              spans: [],
              direction: "ltr",
            },
          ],
        },
      },
    ],
    id: "6",
    slice_type: "homepage_quiz",
    slice_label: null,
    __typename: "PrismicBlogDataBodyHomepageQuiz",
  },
];

const BlogPageQuiz = ({
  slices = _slices || [],
  toggleModal,
  quizResults,
  quizForms,
}) => {
  const [currentSliceIndex, setCurrentSliceIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    Array.from({ length: slices.length }, () => [])
  );
  const [showFinalComponent, setShowFinalComponent] = useState(false);
  const [link, setLink] = useState("/");

  const [otherFields, setOtherFields] = useState([
    {
      name: "quiz_form__how_are_you_developing_ai_",
      value: "",
    },
    {
      name: "quiz_form__what_data_types_do_you_work_with_",
      value: "",
    },
    {
      name: "how_much_data_do_you_work_with",
      value: "",
    },
    {
      name: "quiz_form__how_many_ai_models_do_you_have_in_production_",
      value: "",
    },
    {
      name: "quiz_form__what_is_main_challenge_in_achieving_production_ai_",
      value: "",
    },
  ]);

  const handleSubmit = async (values) => {
    const [cookie] = document.cookie
      .split("; ")
      .filter((x) => x.includes("hubspotutk="));
    const [, hubspotutk] = cookie?.split("=") || ["", ""];

    try {
      if (emailRegex.test(values?.email) && !isDomainBlocked(values?.email)) {
        const requestPayload = {
          fields: [
            {
              objectTypeId: "0-1",
              name: "email",
              value: values?.email,
            },
            ...otherFields.map((field) => ({
              objectTypeId: "0-1",
              name: field.name,
              value: field.value,
            })),
          ],
        };

        if (hubspotutk) {
          Object.assign(requestPayload, {
            context: {
              pageUri: location?.href,
              pageName: parseURL(location.href) || "homepage",
              hutk: hubspotutk,
            },
          });
        }
        
        await axios.post(
          HUBSPOT_PROXY_URL,
          Object.assign(requestPayload, {
            portalId: "25381551",
            formId: "398df28a-8189-4b41-ae25-83927ea0d802",
          })
        );

        setIsFormFilled(true);
      }
    } catch (error) {
      console.log("Error while submitting form:", error);
    }
  };

  useEffect(() => {
    const quizCompleted = localStorage.getItem("BlogQuizCompleted");
    if (!quizCompleted) {
      setIsOpen(true);
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleNext = async () => {
    if (currentSliceIndex < slices.length - 1) {
      setCurrentSliceIndex(currentSliceIndex + 1);
    } else {
      const values = {
        email: localStorage.getItem("Blog Quiz email"),
      };
      await handleSubmit(values);
      setShowFinalComponent(true);
      localStorage.setItem("BlogQuizCompleted", "true");
    }
  };

  const handlePrev = () => {
    if (currentSliceIndex > 0) {
      setCurrentSliceIndex(currentSliceIndex - 1);
    }
  };

  const updateField = (updatedOptions) => {
    const updatedFields = [...otherFields];
    const currentField = updatedFields[currentSliceIndex];
    currentField.value = updatedOptions[currentSliceIndex].join(", ");
    setOtherFields(updatedFields);
  };

  const handleOptionClick = (option) => {
    let updatedOptions = [...selectedOptions];

    if (apiCalled) return;

    if (currentSliceIndex === 1) {
      const sliceOptions = updatedOptions[currentSliceIndex] || [];
      const optionIndex = sliceOptions.indexOf(option);

      if (optionIndex === -1) {
        sliceOptions.push(option);
      } else {
        sliceOptions.splice(optionIndex, 1);
      }
      updatedOptions[currentSliceIndex] = sliceOptions;
      updateField(updatedOptions);
    } else {
      updatedOptions[currentSliceIndex] = [option];
      updateField(updatedOptions);
      // if(currentSliceIndex !== slices.length-1){
      //   handleNext();
      // }
      // if (currentSliceIndex === slices.length - 1) {
      //   setApiCalled(true);
      //   handleNext();
      // } else {
      // handleNext();
      // }
    }

    setSelectedOptions(updatedOptions);

    const dicomSelected = updatedOptions.flat().includes("DICOM / NIfTI");
    if (dicomSelected) {
      setLink("/dicom");
    } else {
      setLink(optionToLinkMap[option] || "/");
    }
  };
  const hadleFormResponse = () => {
    setLoading(true);
    setApiCalled(true);
    handleNext();
  };
  const currentSlice = slices.length > 0 ? slices[currentSliceIndex] : null;
  const dark = currentSlice?.primary?.theme === "dark";

  return (
    <>
      {!isFormFilled && currentSlice && (
        <BlogPageQuizForm
          setIsFormFilled={setIsFormFilled}
          quizFormdata={quizForms}
        />
      )}
      {isOpen && isFormFilled && !showFinalComponent && currentSlice && (
        <div className="">
          <section
            className={` overflow-hidden relative flex flex-col max-w-6xl mx-auto gap-5 lg:min-h-[562px] sm:gap-8 py-10 sm:py-16 px-8 justify-center  bg-white rounded-lg shadow-lg ${
              dark
                ? "quiz_dark_bg text-white"
                : "quiz_light_bg text-purple-1100"
            }`}
          >
            <div className="flex flex-col gap-2.5 justify-center">
              <div className="flex items-center justify-center gap-2">
                <span
                  className={`text-xs text-center uppercase font-manrope font-semibold tracking-[1.2px] ${
                    dark ? "text-purple-350" : " text-purple-1500"
                  }`}
                >
                  {`${currentSliceIndex + 1} out of ${slices.length} QUESTIONS`}
                </span>
              </div>

              <p
                className={`text-center !text-2xl sm:!text-3.25xl !my-0 !font-semibold !font-manrope sm:!leading-10 !tracking-tightest_7 ${
                  dark ? "!text-white" : "!text-purple-1100"
                }`}
              >
                {currentSlice?.primary?.question_title?.text}
              </p>
            </div>
            {dark && (
              <>
                <img
                  src={darkVector}
                  loading="eager"
                  alt="stats"
                  className="absolute left-0 z-0 hidden bottom-[-1px] lg:block"
                />
                <img
                  src={darkVectorTablet}
                  loading="eager"
                  alt="stats"
                  className="absolute left-0 z-0 hidden bottom-32 sm:block lg:hidden"
                />
                <img
                  src={darkVectorMobile}
                  loading="eager"
                  alt="stats"
                  className="absolute left-0 z-0 block bottom-32 sm:hidden"
                />
              </>
            )}

            <div
              className={`grid  gap-5 justify-center max-w-[940px] ${currentSliceIndex === 1 ? "grid-cols-2 lg:grid-cols-3 " : "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"}`}
            >
              {currentSlice?.items?.map((option, index) => (
                <div
                  className={`px-4 sm:px-3.5 py-6 sm:py-7.5 flex justify-center items-center text-center py-auto font-manrope text-base sm:text-lg  xl:min-h-[116px] xl:max-h-[116px] -tracking-[0.9px] cursor-pointer ${
                    selectedOptions[currentSliceIndex]?.includes(
                      option.option.text
                    ) && dark
                      ? "selected_option_dark"
                      : selectedOptions[currentSliceIndex]?.includes(
                            option.option.text
                          ) && !dark
                        ? "selected_option_light"
                        : ""
                  } ${
                    dark
                      ? "text-white font-semibold option_box_dark"
                      : "text-gray-2500 hover:text-gray-3002 font-normal option_box_light"
                  }`}
                  key={index}
                  onClick={() => handleOptionClick(option.option.text)}
                >
                  {option?.option?.text}
                </div>
              ))}
            </div>
            <div className="mx-auto flex flex-col sm:flex-row items-center gap-[23px] z-10">
              {currentSliceIndex !== 0 && (
                <CustomButton
                  onClick={() => handlePrev()}
                  text={"Previous"}
                  buttonClass={`bg-gray-2800 text-gray-2500 hover:bg-white flex-row-reverse text-sm !px-3`}
                  direction={"left"}
                  icon={
                    <NextQuestionIcon
                      iconColor={"#434343"}
                      iconStyle={"rotate-180"}
                    />
                  }
                />
              )}
              {currentSliceIndex >= 0 &&
                currentSliceIndex < slices.length - 1 && (
                  <CustomButton
                    onClick={() => handleNext()}
                    text={"Next question"}
                    buttonClass={`bg-purple-350 text-white hover:bg-[#5E24FF] text-sm !px-3`}
                    direction={"right"}
                    icon={<NextQuestionIcon />}
                    disabled={selectedOptions[currentSliceIndex]?.length === 0}
                  />
                )}

              {currentSliceIndex === slices.length - 1 && (
                <>
                  {loading ? (
                    <div role="status">
                      <div className="flex gap-x-2.5 py-2  rounded-full bg-[#5E24FF] text-white justify-center items-center text-sm !px-3">
                        <span className="font-semibold">Submitting</span>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            className="inline animate-spin"
                          >
                            <path
                              d="M9 18.5C7.78535 18.5 6.60586 18.2627 5.49668 17.7934C4.42441 17.3398 3.46289 16.6895 2.63672 15.8633C1.81055 15.0371 1.16016 14.0756 0.706641 13.0033C0.237305 11.8941 0 10.7146 0 9.5C0 9.1502 0.283008 8.86719 0.632812 8.86719C0.982617 8.86719 1.26562 9.1502 1.26562 9.5C1.26562 10.5441 1.46953 11.5566 1.87383 12.5111C2.26406 13.4322 2.82129 14.2602 3.53145 14.9703C4.2416 15.6805 5.06953 16.2395 5.99062 16.6279C6.94336 17.0305 7.95586 17.2344 9 17.2344C10.0441 17.2344 11.0566 17.0305 12.0111 16.6262C12.9322 16.2359 13.7602 15.6787 14.4703 14.9686C15.1805 14.2584 15.7395 13.4305 16.1279 12.5094C16.5305 11.5566 16.7344 10.5441 16.7344 9.5C16.7344 8.45586 16.5305 7.44336 16.1262 6.48887C15.7373 5.56999 15.1744 4.73494 14.4686 4.02969C13.7641 3.3229 12.9288 2.75991 12.0094 2.37207C11.0566 1.96953 10.0441 1.76562 9 1.76562C8.6502 1.76562 8.36719 1.48262 8.36719 1.13281C8.36719 0.783008 8.6502 0.5 9 0.5C10.2146 0.5 11.3941 0.737305 12.5033 1.20664C13.5756 1.66016 14.5371 2.31055 15.3633 3.13672C16.1895 3.96289 16.8381 4.92617 17.2916 5.99668C17.7609 7.10586 17.9982 8.28535 17.9982 9.5C17.9982 10.7146 17.7609 11.8941 17.2916 13.0033C16.8398 14.0756 16.1895 15.0371 15.3633 15.8633C14.5371 16.6895 13.5738 17.3381 12.5033 17.7916C11.3941 18.2627 10.2146 18.5 9 18.5Z"
                              fill="white"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <CustomButton
                      onClick={() => hadleFormResponse()}
                      text={"Submit responses"}
                      buttonClass={`bg-purple-350 text-white hover:bg-[#5E24FF] text-sm !px-3`}
                      disabled={
                        selectedOptions[currentSliceIndex]?.length === 0
                      }
                    />
                  )}
                </>
              )}
            </div>
          </section>
        </div>
      )}

      {showFinalComponent && (
        <BlogQuizFinal
          quizResultsData={quizResults}
          dark={dark}
          closeModal={handleClose}
          link={link}
          setIsOpen={setIsOpen}
          setShowFinalComponent={setShowFinalComponent}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
};

export default BlogPageQuiz;
