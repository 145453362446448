import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Virtual, Mousewheel, Keyboard } from "swiper/modules";
import "../../../styles/components/Slices/FeatureTurner.scss";
import { useMediaQuery } from "react-responsive";
import SlicesVideoComponent from "../SlicesVideoComponent";
import useWindowWidth from "../../../hooks/useBetterMediaQuery";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const NextArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="43"
    viewBox="0 0 42 43"
    fill="none"
  >
    <path
      d="M19.6977 15.6593C19.3433 15.305 18.792 15.305 18.4377 15.6593C18.0833 16.0137 18.0833 16.565 18.4377 16.9193L23.0445 21.605L18.4377 26.2906C18.0833 26.645 18.0833 27.1962 18.4377 27.5506C18.5952 27.7081 18.8314 27.7868 19.0677 27.7868C19.3039 27.7868 19.5402 27.7081 19.6977 27.5112L24.9345 22.1956C25.2889 21.8412 25.2889 21.29 24.9345 20.9356L19.6977 15.6593Z"
      fill="#595959"
    />
  </svg>
);

const PrevArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
  >
    <path
      d="M22.3023 14.9689C22.6567 14.6145 23.208 14.6145 23.5623 14.9689C23.9167 15.3233 23.9167 15.8745 23.5623 16.2289L18.9555 20.9145L23.5623 25.6002C23.9167 25.9545 23.9167 26.5058 23.5623 26.8602C23.4048 27.0177 23.1686 27.0964 22.9323 27.0964C22.6961 27.0964 22.4598 27.0177 22.3023 26.8208L17.0655 21.5052C16.7111 21.1508 16.7111 20.5995 17.0655 20.2452L22.3023 14.9689Z"
      fill="#595959"
    />
  </svg>
);

const FeatureTurnerSlider = ({ primary, carouselData }) => {
  const sliderRef = useRef();
  const [activeTab, setActiveTab] = useState(0);
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef(null);
  const tabs = carouselData.map(
    (card) => card?.tab_title?.text || card?.cell_title?.text
  );
  const isTablet = useWindowWidth(1023);

  const startProgress = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(intervalRef.current);
          if (isTablet) {
            handleProgressBarEnd();
          } else {
            goToNextTab();
          }
          return 0;
        }
        return prev + 2;
      });
    }, 100);
  };

  useEffect(() => {
    startProgress();
    return () => clearInterval(intervalRef.current);
  }, [activeTab]);

  const goToNextTab = () => {
    const nextTab = (activeTab + 1) % carouselData.length;
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slideNext();
      setActiveTab(nextTab);
      setProgress(0);
    }
  };

  const goToPreviousTab = () => {
    const previousTab =
      activeTab === 0 ? carouselData.length - 1 : activeTab - 1;
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slidePrev();
      setActiveTab(previousTab);
      setProgress(0);
    }
  };

  const handleProgressBarEnd = () => {
    const nextIndex = activeTab + 1 < tabs?.length ? activeTab + 1 : 0;
    if (isTablet && sliderRef.current?.swiper) {
      sliderRef.current.swiper.slideNext();
    }
    setActiveTab(nextIndex);
    setProgress(0);
  };

  const handleTabClick = (index) => {
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slideToLoop(index);
      setActiveTab(index);
      setProgress(0);
      startProgress();
    }
  };
  const allignTabs = primary?.primary?.show_tabs;

  return (
    <div
      className={`flex flex-col ${allignTabs === "horizontal" ? "lg:flex-col" : "lg:flex-row lg:gap-[22px] lg:items-start"} pb-8`}
    >
      <div
        className={`flex flex-col ${allignTabs === "horizontal" ? "lg:flex-row w-full justify-between" : "lg:flex-col"} mb-[22px]`}
      >
        <div
          className={`flex gap-4 ${allignTabs === "horizontal" ? " lg:flex-row " : "lg:flex-col lg:space-y-2 lg:w-[280px] lg:gap-5"} ${isTablet && `turner_tab_slide_opacity`}  overflow-x-auto scroll-smooth no-scrollbar`}
        >
          {!isTablet
            ? tabs
                .map((title, index) => ({ title, index }))
                .sort((a, b) =>
                  a.index === activeTab ? -1 : b.index === activeTab ? 1 : 0
                )
                .map(({ title, index }) => (
                  <button
                    key={index}
                    onClick={() => handleTabClick(index)}
                    className={`flex items-center gap-1.5 ${!isTablet && "min-w-fit"}`}
                  >
                    <p
                      className={`font-inter text-lg sm:text-base lg:text-lg font-medium -tracking-widest_3 text-left text-gray-2700 ${activeTab === index ? " opacity-100" : "opacity-50"}`}
                    >
                      {title}
                    </p>
                    <div
                      className={`relative items-center justify-center w-[18px] h-[18px] ${activeTab === index ? "opacity-100" : "opacity-0"}`}
                    >
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 42 42"
                        className="donut -rotate-90"
                      >
                        <circle
                          cx="21"
                          cy="21"
                          r="15.91549430918954"
                          className="stroke-gray-300"
                          strokeWidth="6"
                          fill="transparent"
                        />
                        <circle
                          cx="21"
                          cy="21"
                          r="15.91549430918954"
                          className="stroke-gray-600"
                          strokeWidth="6"
                          fill="transparent"
                          strokeDasharray={`${progress} ${100 - progress}`}
                          strokeDashoffset="0"
                        />
                      </svg>
                    </div>
                  </button>
                ))
            : tabs.map((title, index) => (
                <button
                  key={index}
                  onClick={() => handleTabClick(index)}
                  className={`flex items-center gap-1.5 min-w-fit`}
                >
                  <div
                    className={`relative ${allignTabs == "horizontal" ? "hidden" : "flex"} items-center justify-center w-[18px] h-[18px] ${activeTab === index ? "opacity-100" : "opacity-0"}`}
                  >
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 42 42"
                      className="donut -rotate-90"
                    >
                      <circle
                        cx="21"
                        cy="21"
                        r="15.91549430918954"
                        className="stroke-gray-300"
                        strokeWidth="6"
                        fill="transparent"
                      />
                      <circle
                        cx="21"
                        cy="21"
                        r="15.91549430918954"
                        className="stroke-gray-600 "
                        strokeWidth="6"
                        fill="transparent"
                        strokeDasharray={`${progress} ${100 - progress}`}
                        strokeDashoffset="0"
                      />
                    </svg>
                  </div>
                  <p
                    className={`font-inter text-base font-medium tracking-tight_1 text-left text-gray-2700 ${activeTab === index ? " opacity-100" : "opacity-50"}`}
                  >
                    {title}
                  </p>
                  <div
                    className={`relative ${allignTabs == "horizontal" ? "flex" : "hidden"} items-center justify-center w-[18px] h-[18px] ${activeTab === index ? "opacity-100" : "opacity-0"}`}
                  >
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 42 42"
                      className="donut -rotate-90"
                    >
                      <circle
                        cx="21"
                        cy="21"
                        r="15.91549430918954"
                        className="stroke-gray-300"
                        strokeWidth="6"
                        fill="transparent"
                      />
                      <circle
                        cx="21"
                        cy="21"
                        r="15.91549430918954"
                        className="stroke-gray-600 "
                        strokeWidth="6"
                        fill="transparent"
                        strokeDasharray={`${progress} ${100 - progress}`}
                        strokeDashoffset="0"
                      />
                    </svg>
                  </div>
                </button>
              ))}
        </div>
        <div>
          {isTablet && (
            <section
              className={`${allignTabs == "horizontal" ? "flex justify-end" : " flex items-start mt-6"}`}
            >
              <button aria-label="prev-btn" onClick={goToPreviousTab}>
                <PrevArrow />
              </button>
              <button aria-label="forward-btn" onClick={goToNextTab}>
                <NextArrow />
              </button>
            </section>
          )}
        </div>
      </div>
      <Swiper
        direction="horizontal"
        keyboard={{
          enabled: true,
          onlyInViewport: true,
        }}
        mousewheel={{
          forceToAxis: true,
        }}
        forceToAxis={true}
        modules={[Virtual, Mousewheel, Keyboard]}
        ref={sliderRef}
        slidesPerView={1}
        loop={true}
        onSlideChange={(swiper) => {
          setActiveTab(swiper.realIndex);
          setProgress(0);
          startProgress();
        }}
        className="slider "
      >
        {carouselData.map((cardData, key) => (
          <SwiperSlide
            virtualIndex={key}
            key={key}
            style={{
              width: "100%",
            }}
          >
            {cardData?.video_link?.url ? (
              <SlicesVideoComponent
                loop
                autoPlay
                muted
                videoComponentClass={`rounded-md flex-shrink-0 aspect-square max-h-[288px] sm:max-h-[568px] h-full w-full`}
                src={cardData?.video_link?.url}
              />
            ) : (
              <GatsbyImage
                image={getImage(cardData?.cell_image)}
                key={cardData?.cell_image?.url}
                alt={cardData?.cell_image?.alt || "cell_image"}
                loading="lazy"
                sizes={`${allignTabs === "horizontal" ? "(max-width: 600px) 100vw, (max-width: 1024px) 50vw, 850px" : "(max-width: 776px) 50vw, 776px"}`}
                className={`rounded-md flex-shrink-0 object-cover aspect-square max-h-[288px] sm:max-h-[568px] h-full w-full`}
              />
            )}
            <div className="mt-[42px] flex flex-col gap-3">
              {cardData?.cell_title?.text && (
                <p className="text-purple-1100 font-manrope font-semibold text-xl sm:text-2xl tracking-tighter_2">
                  {cardData?.cell_title?.text}
                </p>
              )}
              {cardData?.cell_description?.text && (
                <p className="text-gray-2500 text-base font-inter font-normal tracking-tighter_1 sm:tracking-tight_1">
                  {cardData?.cell_description?.text}
                </p>
              )}
            </div>
          </SwiperSlide>
        ))}
        {!isTablet && (
          <section className="flex items-center justify-end gap-3 mt-[22px]">
            <button aria-label="prev-btn" onClick={goToPreviousTab}>
              <PrevArrow />
            </button>
            <button aria-label="forward-btn" onClick={goToNextTab}>
              <NextArrow />
            </button>
          </section>
        )}
      </Swiper>
    </div>
  );
};

export default FeatureTurnerSlider;
