import React, { useContext } from "react";
import HeadingSection from "../../Pieces/HeadingSection";
import FeatureDaliCard from "../../Pieces/FeatureDaliCard";
import "../../../styles/components/Slices/FeatureDali.scss";
import ModalContext from "../../../Contexts/openModal";

const FeatureDali = ({ slice, location }) => {
  const { toggleModal } = useContext(ModalContext);
  return (
    <section className={`relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl`}>
      <article className="py-10 lg:py-20 lg:mx-11">
        {(slice?.primary?.title?.text ||
          slice?.primary?.descriptor_text?.text) && (
          <HeadingSection data={slice?.primary} toggleModal={toggleModal} />
        )}
        <section
          className={`gap-7 sm:gap-4 ${location?.pathname === "/contact-us/" ? "grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1" : "grid grid-cols-[repeat(3,minmax(300px,1fr))] sm:grid-cols-[repeat(3,minmax(368px,1fr))] grid-rows-2"}  overflow-x-auto sm:gap-6 hideScrollBar scroll-smooth cs_slide_opacity_right `}
        >
          {slice?.items?.map((data, index) => (
            <FeatureDaliCard data={data} index={index} location={location} key={index} />
          ))}
        </section>
      </article>
    </section>
  );
};

export default FeatureDali;
