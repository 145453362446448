import React from "react";
import "../../styles/components/logoMarquee.scss";
import { LOGOS_DURATION } from "../../constants";

const LogoMarquee = ({
  logoArray,
  logoBackground = "dark:bg-gray-2600",
  duration,
}) => {
  return (
    <div className="tag-list">
      <InfiniteLoopSlider duration={duration || LOGOS_DURATION}>
        {logoArray?.items.map((item, index) => (
          <Logo
            src={
              item?.brand_image?.url
                ? item?.brand_image?.url
                : item?.brand_logo?.url
            }
            alt={item?.brand_image?.alt || ""}
            key={index}
            logoBackground={logoBackground}
            dimensions={item?.brand_image?.dimensions}
            index={index}
          />
        ))}
      </InfiniteLoopSlider>

      <div className="fade" />
    </div>
  );
};

export default LogoMarquee;

const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
  return (
    <div
      className="loop-slider"
      style={{
        "--duration": `${duration}ms`,
        "--direction": reverse ? "reverse" : "normal",
      }}
    >
      <div className="inner">
        {children}
        {children}
      </div>
    </div>
  );
};

const Logo = ({ src, alt, logoBackground, dimensions,index }) => (
  <div className={`tag ${logoBackground}`}>
    <img
    
      aria-label= { index < 7 ? "false" : "hidden"}
      className="object-scale-down w-auto "
      src={src}
      alt={alt || `Logo` }
      width={dimensions?.width || 100}
      height={dimensions?.height || 100}
      loading="lazy"
      sizes="(max-width: 768px) 100vw, 33vw"
    />
  </div>
);
