import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { HUBSPOT_PROXY_URL, emailRegex } from "../../../constants";
import "../../../styles/components/Slices/ContactUsForm.scss";

import {
  COMPANY_NAME_VALIDATION,
  FIRST_NAME_VALIDATION,
  HOW_CAN_WE_HELP_VALIDATION,
  LAST_NAME_VALIDATION,
  WORK_EMAIL_VALIDATION,
  isDomainBlocked,
  isEducationalEmail,
} from "../../../utilities/helpers";
import { SuccessModal } from "../../DemoModal";

const FormMonet = ({ location, slice, hideSuccessModal = false }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const RequestTrialSchema = Yup.object().shape({
    firstName: FIRST_NAME_VALIDATION,
    lastName: LAST_NAME_VALIDATION,
    email: WORK_EMAIL_VALIDATION,
    company: COMPANY_NAME_VALIDATION,
    how_can_we_help_: HOW_CAN_WE_HELP_VALIDATION,
  });
  const closeSuccess = () => {
    setIsSuccess(false);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const [cookie] = document.cookie
      .split("; ")
      .filter((x) => x.includes("hubspotutk="));
    const [, hubspotutk] = cookie?.split("=") || ["", ""];
    setError("");
    try {
      if (emailRegex.test(values?.email) && !isDomainBlocked(values?.email)) {
        setLoading(true);

        const requestPayload = {
          fields: [
            {
              objectTypeId: "0-1",
              name: "firstname",
              value: values?.firstName,
            },
            {
              objectTypeId: "0-1",
              name: "lastname",
              value: values?.lastName,
            },
            {
              objectTypeId: "0-1",
              name: "email",
              value: values?.email,
            },
            {
              objectTypeId: "0-1",
              name: "company",
              value: values?.company,
            },

            {
              objectTypeId: "0-1",
              name: "how_can_we_help_",
              value: values?.how_can_we_help_,
            },
          ].filter((x) => x.value),
        };
        
        if (hubspotutk) {
          Object.assign(requestPayload, {
            context: {
              pageUri: location?.href,
              pageName: "contact-us",
              hutk: hubspotutk,
            },
          });
        }
        await axios.post(
          HUBSPOT_PROXY_URL,
          Object.assign(requestPayload, {
            portalId: "25381551",
            formId: "d7b51daa-e29b-456b-94d8-9fa01045c492",
          })
        );

        resetForm();
        setIsSuccess(true);
      } else {
        setError("Please enter your work e-mail.");
      }
    } catch (error) {
      console.log("error", error);
      if (
        error?.response?.data &&
        error?.response?.data.errors &&
        error?.response?.data.errors[0]?.message
      ) {
        const [, errorMessage] =
          error?.response?.data?.errors[0]?.message.split(". ") || ["", ""];
        if (errorMessage) {
          setError(errorMessage ?? error?.response?.data?.errors[0]?.message);
        }
      } else {
        setError("SERVER SIDE ERROR");
      }
    } finally {
      setLoading(false);
    }
  };
  const formClass = slice?.primary?.form_class?.text || "";

  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <article className=" lg:py-8 lg:mx-11">
        <div className="flex items-center justify-center min-h-full px-5 py-10 bg-white rounded-2.5xl sm:px-9 mobile_box_shadow ">
          <div className="flex flex-col justify-center lg:flex-row rounded-[20px] demo_container w-full box_alignment all items-center lg:items-stretch">
            <div>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  company: "",
                  how_can_we_help_: "",
                }}
                validationSchema={RequestTrialSchema}
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  touched,
                  values,
                  setTouched,
                  validateForm,
                  setFieldValue,
                  form,
                  isValid,
                  dirty,
                  ...rest
                }) => {
                  return (
                    <Form className={`${formClass}`}>
                      <section className="flex flex-col sm:gap-y-2.5 gap-y-2">
                        <div className="flex flex-col gap-x-7.5 sm:flex-row gap-y-2 sm:gap-y-0">
                          <div className="flex flex-col w-full">
                            <label className="text-gray-2500 placeholder:text-[#A8A4A4] text-xs mb-1.5 font-medium">
                              First name
                              <sup className="text-red-500">*</sup>
                            </label>
                            <Field
                              name="firstName"
                              placeholder="Enter your first name"
                              type="text"
                              className="text-gray-2700 w-full focus:border-[#0958D9] placeholder:text-gray-2200 text-sm rounded-lg placeholder-gray-1600 border-gray-2200 border py-2.5 px-5"
                            />
                            {errors.firstName && touched.firstName ? (
                              <small className="text-red-500">
                                {errors.firstName}
                              </small>
                            ) : null}
                          </div>
                          <div className="flex flex-col w-full">
                            <label className="text-gray-2500 placeholder:text-[#A8A4A4] text-xs font-medium mb-1.5">
                              Last name
                              <sup className="text-red-500">*</sup>
                            </label>
                            <Field
                              name="lastName"
                              placeholder="Enter your last name"
                              type="text"
                              className="text-gray-2700 focus:border-[#0958D9] placeholder:text-gray-2200 text-sm w-full rounded-lg placeholder-gray-1600 border-gray-2200 border py-2.5 px-5"
                            />
                            {errors.lastName && touched.lastName ? (
                              <small className="text-red-500">
                                {errors.lastName}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="flex flex-col gap-x-7.5 sm:flex-row gap-y-2 sm:gap-y-0">
                          <div className="flex flex-col flex-1 w-full">
                            <label className="text-gray-2500 placeholder:text-[#A8A4A4] text-xs font-medium mb-1.5">
                              Company name
                              <sup className="text-red-500">*</sup>
                            </label>

                            <Field
                              name="company"
                              placeholder="Enter your company name"
                              type="text"
                              className="text-gray-2700 focus:border-[#0958D9] placeholder:text-gray-2200 text-sm rounded-lg placeholder-gray-1600 border-gray-2200 border py-2.5 px-5"
                            />
                            {errors.company && touched.company ? (
                              <small className="text-red-500">
                                {errors.company}
                              </small>
                            ) : null}
                          </div>
                          <div className="flex flex-col flex-1 w-full">
                            <label className="text-gray-2500 placeholder:text-[#A8A4A4] text-xs font-medium mb-1.5">
                              Work email
                              <sup className="text-red-500">*</sup>
                            </label>

                            <Field
                              name="email"
                              placeholder="Enter your work email"
                              type="text"
                              className="text-gray-2700 focus:border-[#0958D9] placeholder:text-gray-2200 text-sm rounded-lg placeholder-gray-1600 border-gray-2200 border py-2.5 px-5"
                            />
                            {errors.email && touched.email ? (
                              <small className="text-red-500">
                                {errors.email}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="flex flex-col w-full">
                          <label className="text-gray-2500 placeholder:text-[#A8A4A4] text-xs font-medium mb-1.5">
                            How can we help?
                            <sup className="text-red-500">*</sup>
                          </label>
                          <Field
                            as="textarea"
                            name="how_can_we_help_"
                            placeholder="Tell us more about what you’re trying to solve"
                            className="text-gray-2700 placeholder:text-gray-2200 text-sm rounded-lg placeholder-gray-1600 border-gray-2200 border min-h-[96px] py-2.5 px-5"
                          />
                          {errors.how_can_we_help_ &&
                          touched.how_can_we_help_ ? (
                            <small className="text-red-500">
                              {errors.how_can_we_help_}
                            </small>
                          ) : null}
                        </div>
                        {error && (
                          <small className="text-red-500">{error}</small>
                        )}
                        <div className="flex w-full gap-5">
                          <button
                            type={
                              Object.keys(errors)?.length ? "button" : "submit"
                            }
                            // disabled={loading || !(isValid && dirty)}
                            className="bg-purple-1500 hover:bg-[#5e24ff] font-semibold text-white py-2.5 px-7 sm:text-lg text-base shadow-lg rounded-full w-full mx-auto sm:mt-4 mt-2 tracking-tighter_1 mb-5"
                            {...rest}
                          >
                            {loading ? (
                              <div role="status">
                                <div className="flex gap-x-2.5 justify-center items-center">
                                  <span>Submitting</span>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="19"
                                      viewBox="0 0 18 19"
                                      fill="none"
                                      className="inline animate-spin"
                                    >
                                      <g clip-path="url(#clip0_88_6703)">
                                        <path
                                          d="M9 18.5C7.78535 18.5 6.60586 18.2627 5.49668 17.7934C4.42441 17.3398 3.46289 16.6895 2.63672 15.8633C1.81055 15.0371 1.16016 14.0756 0.706641 13.0033C0.237305 11.8941 0 10.7146 0 9.5C0 9.1502 0.283008 8.86719 0.632812 8.86719C0.982617 8.86719 1.26562 9.1502 1.26562 9.5C1.26562 10.5441 1.46953 11.5566 1.87383 12.5111C2.26406 13.4322 2.82129 14.2602 3.53145 14.9703C4.2416 15.6805 5.06953 16.2395 5.99062 16.6279C6.94336 17.0305 7.95586 17.2344 9 17.2344C10.0441 17.2344 11.0566 17.0305 12.0111 16.6262C12.9322 16.2359 13.7602 15.6787 14.4703 14.9686C15.1805 14.2584 15.7395 13.4305 16.1279 12.5094C16.5305 11.5566 16.7344 10.5441 16.7344 9.5C16.7344 8.45586 16.5305 7.44336 16.1262 6.48887C15.7373 5.56999 15.1744 4.73494 14.4686 4.02969C13.7641 3.3229 12.9288 2.75991 12.0094 2.37207C11.0566 1.96953 10.0441 1.76562 9 1.76562C8.6502 1.76562 8.36719 1.48262 8.36719 1.13281C8.36719 0.783008 8.6502 0.5 9 0.5C10.2146 0.5 11.3941 0.737305 12.5033 1.20664C13.5756 1.66016 14.5371 2.31055 15.3633 3.13672C16.1895 3.96289 16.8381 4.92617 17.2916 5.99668C17.7609 7.10586 17.9982 8.28535 17.9982 9.5C17.9982 10.7146 17.7609 11.8941 17.2916 13.0033C16.8398 14.0756 16.1895 15.0371 15.3633 15.8633C14.5371 16.6895 13.5738 17.3381 12.5033 17.7916C11.3941 18.2627 10.2146 18.5 9 18.5Z"
                                          fill="white"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_88_6703">
                                          <rect
                                            width="18"
                                            height="18"
                                            fill="white"
                                            transform="translate(0 0.5)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                  </span>
                                </div>
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </section>
                    </Form>
                  );
                }}
              </Formik>
              <div
                dangerouslySetInnerHTML={{
                  __html: slice?.primary?.form_bottom_text?.html,
                }}
                className="text-xs text-center text-gray-1200 link_style"
              />
            </div>
          </div>
        </div>
      </article>
      {!hideSuccessModal && (
        <SuccessModal isSuccess={isSuccess} closeSuccess={closeSuccess} />
      )}
    </section>
  );
};

export default FormMonet;
