import React, { useContext } from "react";
import ModalContext from "../../../Contexts/openModal";
import "../../../styles/components/Slices/heroMonet.scss";
import CustomButton from "../../CustomButton";
import LogoMarquee from "../../LogoMarquee";
import graphicPoster from "../../../assets/new-home-page/bg_graphics_poster.webp";
import bg_video from "../../../assets/new-home-page/bg_graphic_flow.webm";
import VideoComponent from "../../VideoComponent";
import SingleEmailForm2 from "../../Pieces/SingleEmailForm2";
import { useMediaQuery } from "react-responsive";

const HeroMonet = ({ slice, location }) => {
  const aiAgentPage = location?.pathname == "/ai-workflows-agents/";
  const isDesktop = useMediaQuery({ maxWidth: 1234, minWidth: 1023 });
  const isMobile = useMediaQuery({ maxWidth: 520 });
  const { toggleModal, setEmail } = useContext(ModalContext);
  const isAlignCenter = slice?.primary?.heading_section_position === "Center";
  const isEmpty = slice?.primary?.trusted_by_logo_alignment === "empty";
  const leftBottom =
    slice?.primary?.trusted_by_logo_alignment === "left bottom";
  const rightBottom =
    slice?.primary?.trusted_by_logo_alignment === "right bottom";

  const logo_marque_data = {
    items: slice.items.map((x) => ({
      brand_image: { ...x.trusted_by_logo },
    })),
  };
  const logosPresent =
    logo_marque_data?.items?.filter((item) => item?.brand_image?.url).length >
    0;

  const scrollToSection = (url) => {
    if (url && url?.charAt(0) === "#") {
      const sectionId = url.substring(1);
      const section = document.getElementById(sectionId);
      if (section) {
        const elementPosition = section.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - 90;
        window.scrollTo({ top: offsetPosition, behavior: "smooth" });
      }
    } else toggleModal();
  };
  const isUrl =
    slice?.primary?.cta_link?.url &&
    slice?.primary?.cta_link?.url?.charAt(0) !== "#";
  const emptySlice = !!(
    slice?.primary?.title?.html || slice?.primary?.description?.text
  );
  return (
    <>
      {emptySlice && (
        <article className="relative">
          {(location?.pathname?.includes("/contact-us/") ||
            location?.pathname?.includes("/press/")) &&
            !isMobile && (
              <VideoComponent
                autoPlay
                poster={graphicPoster}
                className="absolute left-0 right-0 z-0 [mix-blend-mode:darken] top-24 lg:top-0 sm:top-16"
              >
                <source
                  className="row-start-1 row-end-4"
                  src={bg_video}
                  type="video/webm"
                />
              </VideoComponent>
            )}

          <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
            <div
              className={`flex flex-col flex-1 gap-3 py-10 mx-auto lg:mx-11 ${isAlignCenter ? "lg:py-12" : "lg:py-20"}`}
            >
              {slice?.primary?.caption_title?.text && (
                <p
                  className={`text-xs sm:text-base font-medium leading-4 sm:leading-6 uppercase font-inter tracking-widest_1 text-purple-1500  ${isAlignCenter && "text-center"}`}
                >
                  {slice?.primary?.caption_title?.text}
                </p>
              )}

              <div
                className={`flex flex-col ${slice?.primary?.description?.text ? "gap-5" : ""} ${isAlignCenter ? "lg:flex-col justify-center items-center" : "lg:flex-row lg:gap-10"}`}
              >
                <div
                  className={`${location.pathname === "/contact-us/" ? " lg:max-w-full sm:max-w-[580px] " : "max-w-[648px]"} ${location.pathname === "/about-us/" ? "max-w-full " : "max-w-[648px]"} ${location.pathname === "/careers/" ? "max-w-full " : "max-w-[648px]"}   overflow-hidden flex-1 ${isAlignCenter && "flex flex-col justify-center items-center"}`}
                >
                  {slice?.primary?.title?.html && (
                    <div
                      className={`text-gray-2700 text-3.25xl sm:text-4.5xl font-manrope leading-[45px] sm:leading-12 font-semibold tracking-[-1.8px] sm:tracking-tightest_4 color_style ${isAlignCenter ? "text-center sm:pb-2" : "lg:pb-5 "}`}
                      dangerouslySetInnerHTML={{
                        __html: slice?.primary?.title?.html,
                      }}
                    />
                  )}
                  {leftBottom && !isEmpty && logosPresent && !isAlignCenter && (
                    <section className="max-w-[400px] xs:max-w-[648px] marquee_padding_desktop hidden lg:block">
                      {slice?.primary?.show_logo_caption_title && (
                        <p className="pt-8 pb-5 text-xs font-semibold text-gray-600 uppercase font-manrope tracking-widest_1">
                          Trusted by pioneering AI Teams
                        </p>
                      )}
                      <LogoMarquee
                        logoArray={logo_marque_data}
                        logoBackground=""
                      />
                    </section>
                  )}
                  {slice?.primary.show_cta ? (
                    <>
                      {rightBottom && !isEmpty && !isAlignCenter && (
                        <CustomButton
                          link={isUrl && slice?.primary?.cta_link?.url}
                          onClick={() =>
                            scrollToSection(slice?.primary?.cta_link?.url)
                          }
                          text={slice?.primary?.cta_text?.text}
                          buttonClass={`bg-purple-1500 text-white hover:bg-[#5E24FF] mt-5 hidden lg:block`}
                        />
                      )}
                    </>
                  ) : slice?.primary.show_email_cta ? (
                    <>
                      {rightBottom && !isEmpty && !isAlignCenter && (
                        <div className="flex mt-5">
                          <SingleEmailForm2
                            openModal={toggleModal}
                            setEmail={setEmail}
                            formHsClass={slice?.primary?.hs_class?.text}
                            formId={slice?.primary?.hs_id?.text}
                            buttonText={slice?.primary?.cta_text?.text}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`flex-[0.5] overflow-hidden ${isAlignCenter && "flex flex-col justify-center items-center"}`}
                >
                  {slice?.primary?.description?.text && (
                    <div
                      className={`text-lg font-normal leading-7 text-gray-600 lg:max-w-[965px] font-inter tracking-tight_1 ${isAlignCenter && "text-center"}`}
                      dangerouslySetInnerHTML={{
                        __html: slice?.primary?.description?.text,
                      }}
                    />
                  )}
                  {rightBottom &&
                    !isEmpty &&
                    logosPresent &&
                    !isAlignCenter && (
                      <section className="max-w-[400px] xs:max-w-[556px] hidden lg:block">
                        {slice?.primary?.show_logo_caption_title && (
                          <p className="pt-8 pb-5 text-xs font-semibold text-gray-600 uppercase font-manrope tracking-widest_1">
                            Trusted by pioneering AI Teams
                          </p>
                        )}
                        <LogoMarquee
                          logoArray={logo_marque_data}
                          logoBackground=""
                        />
                      </section>
                    )}
                  {slice?.primary.show_cta ? (
                    <>
                      {leftBottom && !isEmpty && (
                        <CustomButton
                          link={isUrl && slice?.primary?.cta_link?.url}
                          onClick={() =>
                            scrollToSection(slice?.primary?.cta_link?.url)
                          }
                          text={slice?.primary?.cta_text?.text}
                          buttonClass={`bg-purple-1500 text-white hover:bg-[#5E24FF] mt-5 ${isAlignCenter && "mb-8"}`}
                        />
                      )}
                      {rightBottom && !isEmpty && (
                        <CustomButton
                          link={isUrl && slice?.primary?.cta_link?.url}
                          onClick={() =>
                            scrollToSection(slice?.primary?.cta_link?.url)
                          }
                          text={slice?.primary?.cta_text?.text}
                          buttonClass={`bg-purple-1500 text-white hover:bg-[#5E24FF] mt-5 mb-8 block  ${!isAlignCenter && "lg:hidden"}`}
                        />
                      )}
                    </>
                  ) : slice?.primary.show_email_cta ? (
                    <>
                      {leftBottom && !isEmpty && (
                        <div className="flex mt-5">
                          <SingleEmailForm2
                            openModal={toggleModal}
                            setEmail={setEmail}
                            formHsClass={slice?.primary?.hs_class?.text}
                            formId={slice?.primary?.hs_id?.text}
                            buttonText={slice?.primary?.cta_text?.text}
                          />
                        </div>
                      )}
                      {rightBottom && !isEmpty && (
                        <div className="flex mt-5">
                          <SingleEmailForm2
                            openModal={toggleModal}
                            setEmail={setEmail}
                            formHsClass={slice?.primary?.hs_class?.text}
                            formId={slice?.primary?.hs_id?.text}
                            buttonText={slice?.primary?.cta_text?.text}
                          />
                        </div>
                      )}
                      {isEmpty && !isAlignCenter && (
                        <div className="mt-5 flex">
                          <SingleEmailForm2
                            openModal={toggleModal}
                            setEmail={setEmail}
                            formHsClass={slice?.primary?.hs_class?.text}
                            formId={slice?.primary?.hs_id?.text}
                            buttonText={
                              isDesktop && aiAgentPage
                                ? "Integrate"
                                : slice?.primary?.cta_text?.text
                            }
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {!isEmpty && logosPresent && (
                    <section
                      className={`max-w-[400px] xs:max-w-[648px]  ${isAlignCenter ? " lg:max-w-4xl marquee_padding_desktop" : "lg:hidden marquee_padding"}`}
                    >
                      {slice?.primary?.show_logo_caption_title && (
                        <p className="pt-8 text-xs font-semibold text-gray-600 uppercase font-manrope tracking-widest_1">
                          Trusted by pioneering AI Teams
                        </p>
                      )}
                      <LogoMarquee logoArray={logo_marque_data} />
                    </section>
                  )}
                  {slice?.primary.show_cta ? (
                    <>
                      {isAlignCenter && isEmpty && (
                        <CustomButton
                          link={isUrl && slice?.primary?.cta_link?.url}
                          onClick={() =>
                            scrollToSection(slice?.primary?.cta_link?.url)
                          }
                          text={slice?.primary?.cta_text?.text}
                          buttonClass={`bg-purple-1500 text-white hover:bg-[#5E24FF] mt-5 `}
                        />
                      )}
                    </>
                  ) : slice?.primary.show_email_cta ? (
                    <>
                      {isAlignCenter && isEmpty && (
                        <div className="flex mt-5">
                          <SingleEmailForm2
                            openModal={toggleModal}
                            setEmail={setEmail}
                            formHsClass={slice?.primary?.hs_class?.text}
                            formId={slice?.primary?.hs_id?.text}
                            buttonText={slice?.primary?.cta_text?.text}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </section>
        </article>
      )}
    </>
  );
};

export default HeroMonet;
