import React, { useEffect, useRef, useState } from "react";
import EncordLogoDarkMode from "../assets/icons/EncordLogoDarkMode.svg";
import EncordLogoLightMode from "../assets/icons/EncordLogoLightMode.svg";
import EncordLogotype from "../assets/images/EncordLogotype.svg";
import CustomButton from "../components/CustomButton.jsx";
import CustomLink from "../components/CustomLink.jsx";
import DemoModal from "../components/DemoModal.jsx";
import Transition from "../utilities/Transition.js";
import CompanyHeaderButton, { company } from "./CompanyHeaderButton";
import ProductHeaderButton from "./ProductHeaderButton";
import ResourcesHeaderButton, { resources } from "./ResourcesHeaderButton.jsx";
import { industries } from "./SolutionsHeaderButton";
import { SHOW_BANNER_PAGES } from "../constants/index.jsx";
import CustomNewsBanner from "../components/CustomNewBanner.jsx";
import { ArrowDown } from "../assets/icons/arrowDownIcon.jsx";
import { ShowFeature } from "../assets/icons/showFeatures.jsx";
import { CloseFeature } from "../assets/icons/closeFeatures.jsx";
import { DownIconHeaderMenu } from "../assets/icons/MobileHeaderIcons/downIcon.js";
import { UpIconHeaderMenu } from "../assets/icons/MobileHeaderIcons/upIcon.js";
import { PlatformIcon } from "../assets/icons/MobileHeaderIcons/platformIcon.js";
import { ResourcesIcon } from "../assets/icons/MobileHeaderIcons/resourcesIcon.js";
import { CompanyIcon } from "../assets/icons/MobileHeaderIcons/companyIcon.js";
import { CustomerIcon } from "../assets/icons/MobileHeaderIcons/customerIcon.js";
import { PricingIcon } from "../assets/icons/MobileHeaderIcons/pricingIcon.js";

export const headerTheme = {
  default: {
    background: "bg-white",
    logo: <EncordLogoLightMode />,
    textColor: "text-gray-2500",
    hoverTextColor: "text-gray-2700",
    loginBtn:
      "mr-3 text-base font-manrope text-gray-2500 font-semibold lg:px-4 lg:py-2 rounded-full border border-gray-2200 hover:border-gray-2500 focus:border-gray-2500",
    icon: "gray",
    hoverIcon: "grayHover",
    bookDemo:
      "text-sm sm:text-base font-manrope font-semibold bg-purple-1500 w-full text-white sm:w-auto px-7 py-2.5 lg:py-2 lg:px-4 rounded-full hover:bg-[#5E24FF]",
  },

  purple: {
    background: "bg-[#210b47f5]",
    logo: <EncordLogoDarkMode />,
    textColor: "text-white",
    hoverTextColor: "text-white",
    loginBtn:
      "mr-3 text-base font-manrope text-purple-2000 bg-white font-semibold lg:px-4 lg:py-2 rounded-full",
    icon: "white",
    hoverIcon: "white",
    bookDemo:
      "text-sm sm:text-base outline_btn font-semibold w-full sm:w-auto lg:py-2 lg:px-4 rounded-full bg-white text-purple-1500",
  },
  purpleWithoutOpacity: {
    background: "bg-[#1E0842] md:!bg-opacity-100",
    logo: <EncordLogoDarkMode />,
    textColor: "text-white",
    hoverTextColor: "text-white",
    loginBtn:
      "mr-3 text-base font-manrope text-[#1E0842] bg-white font-semibold lg:px-4 lg:py-2 rounded-full border border-gray-2200 hover:border-gray-2500 focus:border-gray-2500",
    icon: "white",
    hoverIcon: "white",
    bookDemo:
      "text-sm sm:text-base font-manrope outline_btn font-semibold w-full sm:w-auto lg:py-2 lg:px-4 rounded-full itk-btn_dark text-white text-purple-2000",
  },
  newDefault: {
    background: "bg-gray-2800",
    logo: <EncordLogoLightMode />,
    textColor: "text-gray-2500",
    hoverTextColor: "text-gray-2700",
    loginBtn:
      "mr-3 text-base font-manrope text-gray-2500 font-semibold lg:px-4 lg:py-2 rounded-full border border-gray-2200 hover:border-gray-2500 focus:border-gray-2500",
    icon: "gray",
    hoverIcon: "grayHover",
    bookDemo:
      "text-sm sm:text-base font-manrope font-semibold bg-purple-1500 w-full text-white sm:w-auto px-7 py-2.5 lg:py-2 lg:px-4 rounded-full !duration-0 hover:bg-[#5E24FF]",
  },
  transparent: {
    background: "bg-transparent",
    logo: <EncordLogoDarkMode />,
    textColor: "text-white",
    hoverTextColor: "text-white",
    loginBtn:
      "mr-3 text-base font-manrope text-[#1E0842] bg-white font-semibold lg:px-4 lg:py-2 rounded-full border border-gray-2200 hover:border-gray-2500 focus:border-gray-2500",
    icon: "white",
    hoverIcon: "white",
    bookDemo:
      "text-sm sm:text-base font-manrope outline_btn font-semibold w-full sm:w-auto lg:py-2 lg:px-4 rounded-full itk-btn_dark !duration-0 text-white text-purple-1500",
    topBackground: "bg-transparent",
  },
  menu: {
    background: "bg-transparent",
    logo: <EncordLogoDarkMode />,
    textColor: "text-white",
    hoverTextColor: "text-white",
    loginBtn:
      "mr-3 text-base font-manrope text-[#1E0842] bg-white font-semibold lg:px-4 lg:py-2 rounded-full border border-gray-2200 hover:border-gray-2500 focus:border-gray-2500",
    icon: "white",
    hoverIcon: "white",
    bookDemo:
      "text-sm sm:text-base font-manrope outline_btn font-semibold w-full sm:w-auto lg:py-2 lg:px-4 rounded-full itk-btn_dark !duration-0 text-white text-purple-1500",
    topBackground: "bg-gray-2800",
  },
};

const Header = ({
  location,
  setIsMobileMenuOpen = null,
  theme = "default",
}) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [mobileProductOpen, setMobileProductOpen] = useState(false);
  const [mobileSolutionsOpen, setMobileSolutionsOpen] = useState(false);
  const [mobileCompanyOpen, setMobileCompanyOpen] = useState(false);
  const [mobileResourcesOpen, setMobileResourcesOpen] = useState(false);
  const [mobileAnnotateOpen, setMobileAnnotateOpen] = useState(false);
  const [mobileAutomateOpen, setMobileAutomateOpen] = useState(false);
  const [mobileQualityOpen, setMobileQualityOpen] = useState(false);
  const [mobileIndustriesOpen, setMobileIndustriesOpen] = useState(false);
  const [mobileTeamsOpen, setMobileTeamsOpen] = useState(false);
  const [top, setTop] = useState(true);
  const [mobileAnnotationOpen, setMobileAnnotationOpen] = useState(false);
  const [isProductHover, setIsProductHover] = useState(false);
  const [isSolutionHover, setIsSolutionHover] = useState(false);
  const [isResourceHover, setIsResourceHover] = useState(false);
  const [isCompanyHover, setIsCompanyHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const mobileNav = useRef(null);
  const companyRef = useRef();
  const productRef = useRef();
  const resourceRef = useRef();
  const solutionRef = useRef();

  // useEffect(() => {
  //   isCompanyHover && companyRef.current?.click();
  //   isResourceHover && resourceRef.current?.click();
  //   isProductHover && productRef.current?.click();
  //   isSolutionHover && solutionRef.current?.click();
  // });

  const handleProductEnter = () => {
    setIsCompanyHover(false);
    setIsResourceHover(false);
    setIsSolutionHover(false);
    setIsProductHover(true);
  };
  const handleSolutionEnter = () => {
    setIsCompanyHover(false);
    setIsResourceHover(false);
    setIsProductHover(false);
    setIsSolutionHover(true);
  };

  const handleResourceEnter = () => {
    setIsCompanyHover(false);
    setIsSolutionHover(false);
    setIsProductHover(false);
    setIsResourceHover(true);
  };

  const handleCompanyEnter = () => {
    setIsSolutionHover(false);
    setIsProductHover(false);
    setIsResourceHover(false);
    setIsCompanyHover(true);
  };

  const handleLeave = () => {
    setIsSolutionHover(false);
    setIsProductHover(false);
    setIsResourceHover(false);
    setIsCompanyHover(false);
  };
  const [isHovered, setIsHovered] = useState(false);
  const [pricingHover, setPricingHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handlepricingEnter = () => {
    setPricingHover(true);
  };

  const handlepricingLeave = () => {
    setPricingHover(false);
  };
  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen || mobileNav.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, []);

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, []);

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  const toggleMobileProduct = () => {
    setMobileProductOpen(!mobileProductOpen);
    setMobileAnnotateOpen(false);
  };

  const toggleMobileSolution = () => {
    setMobileSolutionsOpen(!mobileSolutionsOpen);
    setMobileTeamsOpen(false);
    setMobileAnnotationOpen(false);
  };

  const toggleMobileCompany = () => {
    setMobileCompanyOpen(!mobileCompanyOpen);
  };

  const toggleMobileResources = () => {
    setMobileResourcesOpen(!mobileResourcesOpen);
  };

  const toggleIndustriesOpen = () => {
    setMobileIndustriesOpen(!mobileIndustriesOpen);
  };

  const getDownCaret = () => {
    return (
      <i
        className="fa fa-caret-down"
        style={{ color: "gray", float: "right", marginRight: "5%" }}
      />
    );
  };

  const getUpCaret = () => {
    return (
      <i
        className="fa fa-caret-up"
        style={{ color: "gray", float: "right", marginRight: "5%" }}
      />
    );
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <header
    
      className={`backdrop-blur-[10px] lg:backdrop-blur-[0px] fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${mobileNavOpen
          ? `bg-gray-2800 lg:bg-transparent `
          : top
            ? `${headerTheme[theme].topBackground}`
            : `shadow-lg ${headerTheme[theme].background} !backdrop-blur-[10px]`
        } `}
    >
      {SHOW_BANNER_PAGES.includes(location?.pathname) && (
        <CustomNewsBanner
          location={location}
          localStorageVariableName="wednesdayWebinar"
        />
      )}
      <div className="z-10 px-5 mx-auto bg-transparent max-w-7xl sm:px-6 xl:px-16">
        <div className="flex items-center justify-between py-4 max-h-[72px] lg:py-[15px]">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <CustomLink arialabel="encord-logo" to="/" className="block">
              {mobileNavOpen ? (
                <EncordLogoLightMode />
              ) : (
                headerTheme[theme].logo
              )}
            </CustomLink>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden lg:flex md:flex-grow">
            {/* Desktop menu links */}
            <ul className="flex items-center justify-end flex-grow flex-shrink-0 gap-y-12 gap-x-7.5 flex-nowrap">
              {/* 1st level: hover */}
              <li>
                <ProductHeaderButton
                  theme={theme}
                  handleEnter={handleProductEnter}
                  handleLeave={handleLeave}
                  ref={productRef}
                  isHover={isProductHover}
                />
              </li>

              {/* <li>
                <SolutionsHeaderButton
                  theme={theme}
                  handleEnter={handleSolutionEnter}
                  handleLeave={handleLeave}
                  ref={solutionRef}
                  isHover={isSolutionHover}
                />
              </li> */}

              <li>
                <CustomLink
                  to="/customers/"
                  className={`inline-flex items-center text-sm lgxl_mid:text-base font-medium ${headerTheme[theme].textColor} focus:outline-none`}
                >
                  Customers
                </CustomLink>
              </li>

              <li>
                <CustomLink
                  to="/pricing/"
                  className={`inline-flex items-center text-sm lgxl_mid:text-base font-medium ${headerTheme[theme].textColor} focus:outline-none`}
                >
                  Pricing
                </CustomLink>
              </li>

              <li>
                <ResourcesHeaderButton
                  theme={theme}
                  handleEnter={handleResourceEnter}
                  handleLeave={handleLeave}
                  ref={resourceRef}
                  isHover={isResourceHover}
                />
              </li>
              <li>
                <CompanyHeaderButton
                  theme={theme}
                  handleEnter={handleCompanyEnter}
                  handleLeave={handleLeave}
                  ref={companyRef}
                  isHover={isCompanyHover}
                />
              </li>
            </ul>

            {/* Desktop sign in links */}
            <ul className="flex flex-wrap items-center justify-end flex-grow">
              {/* <li>
                <Link
                  to="https://github.com/encord-team/encord-active/"
                  target="_blank"
                  className="mr-4 text-black bg-gray-500 btn-sm hover:bg-gray-400"
                >
                  <img
                    className="w-5 h-auto mr-2"
                    src="https://encord.cdn.prismic.io/encord/0e203f57-bcdc-4d26-8db8-393ce29823f8_svgviewer-output+%281%29.svg"
                    alt="GitHub"
                    width={0}
                    height={0}
                  />
                  <svg
                    aria-hidden="true"
                    className="star-icon"
                    height="16"
                    viewBox="0 0 16 16"
                    version="1.1"
                    width="16"
                    data-view-component="true"
                  >
                    <path
                      fill="#eac54f"
                      d="M8 .25a.75.75 0 01.673.418l1.882 3.815 4.21.612a.75.75 0 01.416 1.279l-3.046 2.97.719 4.192a.75.75 0 01-1.088.791L8 12.347l-3.766 1.98a.75.75 0 01-1.088-.79l.72-4.194L.818 6.374a.75.75 0 01.416-1.28l4.21-.611L7.327.668A.75.75 0 018 .25z"
                    ></path>
                  </svg>
                </Link>
              </li> */}

              <li>
                <CustomButton
                  link="https://app.encord.com/login"
                  text="Login"
                  buttonClass={headerTheme[theme].loginBtn}
                />
              </li>

              <li>
                <CustomButton
                  text="Book a demo"
                  buttonClass={headerTheme[theme].bookDemo}
                  onClick={openModal}
                />
              </li>
            </ul>
          </nav>

          {/* Mobile menu */}
          <div className="flex lg:hidden">
            {/* Hamburger button */}
            <button
              className={`hamburger ${mobileNavOpen && "active"}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => {
                setMobileNavOpen(!mobileNavOpen);
                setIsMobileMenuOpen && setIsMobileMenuOpen(!mobileNavOpen);
              }}
            >
              <span className="sr-only">Menu</span>
              <svg
                className={`w-6 h-6 ${headerTheme[theme].textColor} fill-current`}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  fill:
                    !mobileNavOpen && theme === "transparent"
                      ? "white"
                      : "black",
                }}
              >
                <rect y="4" width="24" height="2" />
                <rect y={mobileNavOpen ? "12" : "11"} width="24" height="2" />
                <rect y={mobileNavOpen ? "12" : "18"} width="24" height="2" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <div ref={mobileNav}>
              <Transition
                show={mobileNavOpen}
                tag="nav"
                id="mobile-nav"
                cla
                className="absolute left-0 z-20 w-full h-screen pb-16 overflow-scroll transition-all duration-300 ease-in-out bg-gray-2800 top-full"
                enter="transition ease-out duration-200 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
              >
                <ul className="px-5 py-2.5 bg-gray-2800 font-medium">
                  <li className="">
                    <button
                      onClick={toggleMobileProduct}
                      className="py-3.5 group text-left items-center font-medium flex gap-2 flex-row text-gray-2500 focus:text-purple-1100"
                      style={{ width: "100%" }}
                    >
                      <PlatformIcon
                        stroke={mobileProductOpen ? "#5E44FF" : "#434343"}
                      />
                      Platform{" "}
                      {mobileProductOpen ? (
                        <UpIconHeaderMenu />
                      ) : (
                        <DownIconHeaderMenu />
                      )}
                      {!mobileProductOpen ? getDownCaret() : getUpCaret()}
                    </button>

                    {mobileProductOpen && (
                      <ul className="pl-4">
                        <li>
                          <button
                            onClick={() =>
                              setMobileAutomateOpen(!mobileAutomateOpen)
                            }
                            className=" text-left py-3.5 items-center font-medium flex gap-2 flex-row text-gray-2500 focus:text-purple-1100"
                            style={{ width: "100%" }}
                          >
                            Product{" "}
                            {mobileAutomateOpen ? (
                              <UpIconHeaderMenu />
                            ) : (
                              <DownIconHeaderMenu />
                            )}
                            {!mobileAutomateOpen
                              ? getDownCaret()
                              : getUpCaret()}
                          </button>

                          {mobileAutomateOpen && (
                            <ul className="pl-1">
                              <li>
                                <CustomLink
                                  to="/encord-index/"
                                  className="flex py-3.5 text-sm font-medium text-gray-2500 hover:text-gray-900"
                                >
                                  Data management & curation
                                </CustomLink>
                              </li>
                              {/* <li>
                                <CustomLink
                                  to="/automate/"
                                  className="flex py-3.5 text-sm font-medium text-gray-2500 hover:text-gray-900"
                                >
                                  AI-assisted labelling
                                </CustomLink>
                              </li> */}
                              <li>
                                <CustomLink
                                  to="/annotate/"
                                  className="flex py-3.5 text-sm font-medium text-gray-2500 hover:text-gray-900"
                                >
                                  Data annotation
                                </CustomLink>
                              </li>
                              <li>
                                <CustomLink
                                  to="/active/"
                                  className="flex py-3.5 text-sm font-medium text-gray-2500 hover:text-gray-900"
                                >
                                  Model evaluation
                                </CustomLink>
                              </li>
                              <li>
                                <CustomLink
                                  to="/ai-workflows-agents/"
                                  className="flex py-3.5 text-sm font-medium text-gray-2500 hover:text-gray-900"
                                >
                                  Agents
                                </CustomLink>
                              </li>
                            </ul>
                          )}
                        </li>

                        <li>
                          <button
                            onClick={() =>
                              setMobileAnnotateOpen(!mobileAnnotateOpen)
                            }
                            className="py-3.5 text-left items-center flex gap-2 font-medium flex-row text-gray-2500 focus:text-purple-1100"
                            style={{ width: "100%" }}
                          >
                            Modalities
                            <div>
                              {mobileAnnotateOpen ? (
                                <UpIconHeaderMenu />
                              ) : (
                                <DownIconHeaderMenu />
                              )}
                            </div>
                            {!mobileAnnotateOpen
                              ? getDownCaret()
                              : getUpCaret()}
                          </button>

                          {mobileAnnotateOpen && (
                            <ul className="pl-1">
                              <li>
                                <CustomLink
                                  to="/multimodal/"
                                  className="flex py-3.5 text-sm font-medium text-gray-2500 hover:text-gray-900"
                                >
                                  Multimodal
                                </CustomLink>
                              </li>
                              <li>
                                <CustomLink
                                  to="/image/"
                                  className="flex py-3.5 text-sm font-medium text-gray-2500 hover:text-gray-900"
                                >
                                  Image
                                </CustomLink>
                              </li>
                              <li>
                                <CustomLink
                                  to="/video/"
                                  className="flex py-3.5 text-sm font-medium text-gray-2500 hover:text-gray-900"
                                >
                                  Video
                                </CustomLink>
                              </li>
                              <li>
                                <CustomLink
                                  to="/document/"
                                  className="flex py-3.5 text-sm font-medium text-gray-2500 hover:text-gray-900"
                                >
                                  Document & text
                                </CustomLink>
                              </li>
                              <li>
                                <CustomLink
                                  to="/audio/"
                                  className="flex py-3.5 text-sm font-medium text-gray-2500 hover:text-gray-900"
                                >
                                  Audio
                                </CustomLink>
                              </li>
                              <li>
                                <CustomLink
                                  to="/dicom/"
                                  className="flex py-3.5 text-sm font-medium text-gray-2500 hover:text-gray-900"
                                >
                                  DICOM & NIfTI
                                </CustomLink>
                              </li>
                            </ul>
                          )}
                        </li>

                        {/* <li className="py-3.5">
                          <CustomLink
                            to="/active/"
                            className="flex text-left text-gray-2500 hover:text-gray-900"
                            style={{ width: "100%" }}
                          >
                            Active
                          </CustomLink>
                        </li> */}
                        {/* <li className="py-2 my-2 border-t border-b border-gray-200 border-dotted">
                          <button
                            className="py-2 text-left text-gray-600 hover:text-gray-900"
                            style={{ width: "100%" }}
                          >
                            Apollo
                            <span className="text-[#531DAB] text-xs px-1 bg-[#F9F0FF] rounded-3xl self-center ml-2.5">
                              COMING SOON
                            </span>
                          </button>
                        </li> */}
                      </ul>
                    )}
                  </li>
                  <div className="h-[2px] bg-gray-1600" />
                  {/* 
                  <li className="py-2 my-2 border-t border-b border-gray-200 border-dotted">
                    <button
                      onClick={toggleIndustriesOpen}
                      className="py-2 text-left text-gray-600 hover:text-gray-900"
                      style={{ width: "100%" }}
                    >
                      Industries{" "}
                      {!mobileIndustriesOpen ? getDownCaret() : getUpCaret()}
                    </button>
                    {/* <li className="py-2 my-2 border-t border-b border-gray-200 border-dotted">
                          <button
                            onClick={() =>
                              setMobileAnnotationOpen(!mobileAnnotationOpen)
                            }
                            className="py-2 text-left text-gray-600 hover:text-gray-900"
                            style={{ width: "100%" }}
                          >
                            For Annotation{" "}
                            {!mobileAnnotationOpen
                              ? getDownCaret()
                              : getUpCaret()}
                          </button>

                          {mobileAnnotationOpen && (
                            <ul className="pl-4">
                              <li>
                                <CustomLink
                                  to="/image/"
                                  className="flex py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                                >
                                  Image
                                </CustomLink>
                              </li>
                              <li>
                                <CustomLink
                                  to="/video/"
                                  className="flex py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                                >
                                  Video
                                </CustomLink>
                              </li>
                              <li>
                                <CustomLink
                                  to="/dicom/"
                                  className="flex py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                                >
                                  DICOM & NIfTI
                                </CustomLink>
                              </li>

                              <li>
                                <CustomLink
                                  to="/sar/"
                                  className="flex py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                                >
                                  Synthetic-aperture radar
                                </CustomLink>
                              </li>

                              <li>
                                <CustomLink
                                  to="/ecg-annotation-tool/"
                                  className="flex py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                                >
                                  ECG annotation
                                </CustomLink>
                              </li>
                            </ul>
                          )}
                        </li> */}

                  {mobileIndustriesOpen && (
                    <ul className="pl-4">
                      {industries?.map((item, index) => {
                        return (
                          <li key={index}>
                            <CustomLink
                              to={item?.href}
                              className="flex py-3.5 text-sm font-medium text-gray-600 hover:text-gray-900"
                            >
                              {item?.text}
                            </CustomLink>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {/* <li className="py-2 my-2 border-t border-b border-gray-200 border-dotted">
                          <button
                            onClick={() => setMobileTeamsOpen(!mobileTeamsOpen)}
                            className="py-2 text-left text-gray-600 hover:text-gray-900"
                            style={{ width: "100%" }}
                          >
                            For teams{" "}
                            {!mobileTeamsOpen ? getDownCaret() : getUpCaret()}
                          </button>

                          {mobileTeamsOpen && (
                            <ul className="pl-4">
                              <li>
                                <CustomLink
                                  to="/clinical-operations/"
                                  className="flex py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                                >
                                  Clinical operations
                                </CustomLink>
                              </li>
                              <li>
                                <CustomLink
                                  to="/data-operations/"
                                  className="flex py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                                >
                                  Data operations
                                </CustomLink>
                              </li>
                              <li>
                                <CustomLink
                                  to="/machine-learning/"
                                  className="flex py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                                >
                                  Machine learning
                                </CustomLink>
                              </li>
                              <li>
                                <CustomLink
                                  to="/annotation-teams/"
                                  className="flex py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                                >
                                  Workforce
                                </CustomLink>
                              </li>
                            </ul>
                          )}
                        </li> */}
                  {/* </li>  */}

                  <li>
                    <button
                      onClick={toggleMobileResources}
                      className="text-left py-3.5 flex flex-row items-center gap-2 font-medium text-gray-2500 active:text-purple-1100"
                      style={{ width: "100%" }}
                    >
                      <ResourcesIcon
                        stroke={mobileResourcesOpen ? "#5E44FF" : "#434343"}
                      />
                      Resources{" "}
                      {mobileResourcesOpen ? (
                        <UpIconHeaderMenu />
                      ) : (
                        <DownIconHeaderMenu />
                      )}
                      {!mobileResourcesOpen ? getDownCaret() : getUpCaret()}
                    </button>
                    {mobileResourcesOpen && (
                      <ul className="pl-6">
                        {resources?.map((x, index) => {
                          return (
                            <li key={index}>
                              <a
                                href={x.href}
                                className="flex py-3.5 text-sm font-medium text-gray-600 hover:text-gray-900"
                              >
                                {x?.name}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                  <div className="h-[2px] bg-gray-1600" />

                  <li>
                    <button
                      onClick={toggleMobileCompany}
                      className="text-left py-3.5 flex flex-row gap-2 items-center font-medium text-gray-2500 active:text-purple-1100"
                      style={{ width: "100%" }}
                    >
                      <CompanyIcon
                        stroke={mobileCompanyOpen ? "#5E44FF" : "#434343"}
                      />
                      Company{" "}
                      {mobileCompanyOpen ? (
                        <UpIconHeaderMenu />
                      ) : (
                        <DownIconHeaderMenu />
                      )}
                      {!mobileCompanyOpen ? getDownCaret() : getUpCaret()}
                    </button>
                    {mobileCompanyOpen && (
                      <ul className="pl-6">
                        {company.map((item, index) => {
                          return (
                            <li key={index}>
                              <CustomLink
                                to={item?.href}
                                className="flex py-3.5 text-sm font-medium text-gray-600 hover:text-gray-900"
                              >
                                {item?.name}
                              </CustomLink>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                  <div className="h-[2px] bg-gray-1600" />
                  <li
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className="py-3.5"
                  >
                    <CustomLink
                      to="/customers/"
                      className="flex text-left items-center gap-2 font-medium text-gray-2500 active:text-purple-1100"
                      style={{ width: "100%" }}
                    >
                      <CustomerIcon
                        stroke={isHovered ? "#5E44FF" : "#434343"}
                      />
                      Customers
                    </CustomLink>
                  </li>
                  <div className="h-[2px] bg-gray-1600" />
                  <li
                    onMouseEnter={handlepricingEnter}
                    onMouseLeave={handlepricingLeave}
                    className="py-3.5"
                  >
                    <CustomLink
                      to="/pricing/"
                      className="flex text-left items-center gap-2 font-medium text-gray-2500 active:text-purple-1100"
                      style={{ width: "100%" }}
                    >
                      <PricingIcon
                        stroke={pricingHover ? "#5E44FF" : "#434343"}
                      />
                      Pricing
                    </CustomLink>
                  </li>
                  <div className="h-[2px] bg-gray-1600" />
                </ul>
                {/* <DicomNavigationBanner /> */}
                <div className="px-8 pt-6 pb-8 border-t bg-gray-2800 border-gray-200 border-none">
                  <CustomLink
                    to={"/try-it-free/"}
                    className="w-full text-white bg-[#4F33CA] btn-sm"
                  >
                    Book a demo
                  </CustomLink>
                </div>
              </Transition>
            </div>
          </div>
        </div>
        <DemoModal
          isOpen={isOpen}
          hideSuccessModal={location?.pathname?.includes("/pricing")}
          closeModal={closeModal}
          location={location}
        />
      </div>
    </header>
  );
};

export default Header;
