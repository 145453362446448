import React, { useContext } from "react";
import CustomButton from "../../CustomButton";
import { Chevron } from "../../../assets/icons";
import posterVideo from "../../../assets/videoPoster.png";
import "../../../styles/components/Slices/SectionRaphael.scss";
import VideoComponent from "../../VideoComponent";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import HeadingSection from "../../Pieces/HeadingSection";
import ModalContext from "../../../Contexts/openModal";
import SingleEmailForm2 from "../../Pieces/SingleEmailForm2";

const Video = ({ src, posterImage }) => {
  return (
    <VideoComponent
      poster={posterImage || posterVideo}
      data-aos="fade-up"
      data-aos-delay="150"
      key={src}
      className="rounded-md"
    >
      <source src={src} type="video/mp4" />
    </VideoComponent>
  );
};
const HeaderSection = ({ slice }) => {
  const { isOpen, toggleModal, email, setEmail } = useContext(ModalContext);
  return (
    <section className="flex flex-col gap-4 mb-10">
      {slice?.primary?.show_section_caption &&
        slice?.primary?.section_caption_text?.text && (
          <p
            className={`inline text-xs font-semibold leading-4 max-w-fit  uppercase font-manrope tracking-widest_1  ${slice?.primary?.section_caption_title_style === "rounded rectangle" ? "bg_text":"text-purple-1500"}`}
          >
            {slice?.primary?.section_caption_text?.text}
          </p>
        )}
      {slice?.primary?.section_title?.text && (
        <p
          className={`text-2.5xl sm:text-3.25xl text-purple-1100 font-manrope text-left font-semibold leading-9 tracking-[-1.5px] max-w-full`}
        >
          {slice?.primary?.section_title?.text}
        </p>
      )}
      {slice?.primary?.section_description?.text && (
        <p
          className={` leading-6 sm:leading-7 text-base lg:text-lg text-gray-600 font-normal tracking-tight_1 font-inter `}
        >
          {slice?.primary?.section_description?.text}
        </p>
      )}
      {slice?.primary?.show_section_cta ? (
        <CustomButton
          onClick={() => toggleModal()}
          buttonClass={`bg-purple-1500 text-white hover:bg-[#5E24FF]`}
          text={slice?.primary?.section_cta_text?.text}
          // link={slice?.primary?.section_cta_link?.url}
        />
      ) : slice?.primary?.show_email_cta ? (
        <SingleEmailForm2 buttonText={slice?.primary?.section_cta_text?.text} />
      ) : (
        ""
      )}
    </section>
  );
};

const SectionRaphael = ({ slice }) => {
  const isReversed = slice?.primary?.reversed;
  const isWhiteFilled = slice?.primary?.border_style == "white filled";
  const isGreyFilled = slice?.primary?.border_style == "grey filled";
  const topSpacing = slice?.primary?.top_spacing;
  const bottomSpacing = slice?.primary?.bottom_spacing;
  return (
    <section
      className={`bg-gray-2800 ${topSpacing === "80" && "sm:pt-20 pt-8"} ${topSpacing === "40" && "sm:pt-10 pt-8"} ${topSpacing === "0" && "pt-0"} ${bottomSpacing === "80" && "sm:pb-20 pb-8"} ${bottomSpacing === "40" && "sm:pb-10 pb-8"} ${bottomSpacing === "0" && "pb-0"}`}
    >
      <section className="max-w-6xl mx-auto lg:px-6 sm:px-12 xl:px-0  px-4 ">
        {(slice?.primary?.section_caption_text?.text ||
          slice?.primary?.section_title?.text ||
          slice?.primary?.section_description?.text) && (
          <HeaderSection slice={slice} />
        )}

        <section
          className={`flex ${isReversed ? "lg:flex-row-reverse flex-col-reverse" : "lg:flex-row flex-col"} gap-[22px] sm:gap-10 items-center `}
        >
          <div
            className={`p-2.5 lg:max-w-[496px] lg:min-w-[496px] rounded-md ${isWhiteFilled ? " bg-white" : isGreyFilled ? "bg-gray-1600 " : "border border-gray-1600"}`}
          >
            {slice?.primary?.video_link?.url ? (
              <div className="w-full rounded-md">
                <Video
                  src={slice?.primary?.video_link?.url}
                  posterImage={posterVideo}
                />
              </div>
            ) : (
              slice?.primary?.image?.url && (
                <GatsbyImage
                  image={getImage(slice?.primary?.image)}
                  className="rounded-md"
                  alt={slice?.primary?.image?.alt || "raphael_image"}
                />
              )
            )}
          </div>
          {/* <div
          className={`p-2.5 rounded-sm ${isWhiteFilled ? " bg-white" : isGreyFilled ? "bg-gray-1600 " : "border border-gray-1600"}`}
        >
          <img src={slice?.primary?.image?.url} alt={slice?.primary?.image?.alt || 'raphael_image'} />
        </div> */}

          <div className="flex flex-col lg:px-5 lg:py-6 gap-4 sm:gap-3.5">
            {slice?.primary?.show_caption_title &&
              slice?.primary?.caption_title?.text && (
                <p
                  className={`inline text-xs font-semibold leading-4 max-w-fit  uppercase font-manrope tracking-widest_1  ${(slice?.primary?.caption_title_style || data?.caption_title_dropdown) === "rounded rectangle" && "bg_text"}`}
                >
                  {slice?.primary?.caption_title?.text}
                </p>
              )}
            <div
              dangerouslySetInnerHTML={{
                __html: slice?.primary?.title?.html,
              }}
              className="font-manrope strong_text text-2xl text-[#262626] font-semibold tracking-[-1.5px]"
            />
            <p className="text-base font-normal text-gray-600 tracking-tight_1">
              {slice?.primary?.description?.text}
            </p>
            {slice?.primary?.show_cta && (
              <CustomButton
                direction={`right`}
                buttonClass={`bg-gray-1600 text-gray-2500`}
                icon={<Chevron />}
                link={slice?.primary?.cta_link?.url}
                text={slice?.primary?.cta_text?.text}
              />
            )}
          </div>
        </section>
      </section>
    </section>
  );
};
export default SectionRaphael;
