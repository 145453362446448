import React, { useContext, useEffect, useRef, useState } from "react";
import HeadingSection from "../../Pieces/HeadingSection";
import ModalContext from "../../../Contexts/openModal";
import SectionRandCard from "../../Pieces/SectionRandCard";
import "../../../styles/components/Slices/SectionRand.scss";
import useWindowWidth from "../../../hooks/useBetterMediaQuery";

const SectionRand = ({ slice }) => {
  const topSpacing = slice?.primary?.top_spacing;
  const bottomSpacing = slice?.primary?.bottom_spacing;
  const lastItemRef = useRef(null);
  const containerRef = useRef(null);
  const [isLastVisible, setIsLastVisible] = useState(false);
  const { toggleModal } = useContext(ModalContext);

  const emptyHeading = !!(
    slice?.primary?.caption_title?.text ||
    slice?.primary?.show_cta ||
    slice?.primary?.description?.text ||
    slice?.primary?.title?.text
  );
  const isDesktop = useWindowWidth(1023);

  useEffect(() => {
    if (lastItemRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => setIsLastVisible(entry.isIntersecting),
        { root: containerRef.current, threshold: 1.0 }
      );
      observer.observe(lastItemRef.current);

      return () => observer.disconnect();
    }
  }, []);

  return (
    <section
      className={`py-8 ${topSpacing === "80" && "sm:pt-20"} ${
        topSpacing === "40" && "sm:pt-10"
      } ${bottomSpacing === "80" && "sm:pb-20"} ${
        bottomSpacing === "40" && "sm:pb-10"
      } relative px-4 mx-auto sm:px-12 lg:px-5 xl:px-0 max-w-6xl`}
    >
      {emptyHeading && (
        <HeadingSection
          data={slice?.primary}
          toggleModal={toggleModal}
          descriptionStyle="max-w-[890px]"
          textAreaStyle="gap-4"
          headingStyle="text-2.5xl sm:text-3.25xl text-purple-1100"
          sectionStyle="lg:!pb-10"
        />
      )}
      <div
        ref={containerRef}
        className={` gap-[18px] ${isDesktop ? " grid grid-cols-4" : ` flex ${isLastVisible ? "" : "rand_card_slide_opacity"}  overflow-hidden overflow-x-auto scroll-smooth no-scrollbar`}`}
      >
        {slice?.items.map((card, index) => {
          const isLastItem = index === slice.items.length - 1;
          return (
            <div ref={isLastItem ? lastItemRef : null}>
              <SectionRandCard cardData={card} key={index} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default SectionRand;
