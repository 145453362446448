import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { emailRegex, HUBSPOT_PROXY_URL } from "../../../constants";
import {
  isDomainBlocked,
  WORK_EMAIL_VALIDATION,
} from "../../../utilities/helpers";

const SingleEmailFormFormSchema = Yup.object().shape({
  email: WORK_EMAIL_VALIDATION,
});

const GlossaryCtaForm = ({
  openModal,
  setEmail,
  formHsClass,
  portalId = "25381551",
  formId = "c90a5ae1-1852-43f5-9346-a57837b9f470",
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async ({ email = "" }, { resetForm }) => {
    try {
      const [cookie] = document.cookie
        .split("; ")
        .filter((x) => x.includes("hubspotutk="));
      const [, hubspotutk] = cookie?.split("=") || ["", ""];
      if (emailRegex.test(email) && !isDomainBlocked(email)) {
        setLoading(true);
        const requestPayload = {
          fields: [
            {
              objectTypeId: "0-1",
              name: "email",
              value: email,
            },
          ],
        };

        if (hubspotutk) {
          Object.assign(requestPayload, {
            context: {
              hutk: hubspotutk,
              pageUri: location?.href,
              pageName: location.pathname,
            },
          });
        }
        await axios.post(
          HUBSPOT_PROXY_URL,
          Object.assign(requestPayload, {
            portalId,
            formId,
          })
        );
        /**
         * Append custom properties to Heap
         */
        if (window.heap) {
          window.heap.addUserProperties({
            marketing_email: email,
          });
        }
        setEmail && setEmail(email);
        openModal && openModal();
        resetForm();
      }
    } catch (error) {
      console.log("error while submitting form");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={SingleEmailFormFormSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        values,
        setTouched,
        validateForm,
        setFieldValue,
        form,
        isValid,
        dirty,
        ...rest
      }) => {
        return (
          <section className="flex flex-col w-full sm:max-w-[474px] lg:max-w-full justify-center items-center">
            <Form
              className={`w-full relative gap-3 mb-4 ${formHsClass} flex  flex-col max-w-[180px]`}
            >
              <Field
                name="email"
                placeholder="Your work email"
                type="email"
                className={`w-full  text-gray-2500 placeholder-gray-600 focus:rounded-4xl focus:!border-purple-500 focus:!ring-purple-500 rounded-4xl  placeholder:text-xs placeholder:tracking-[-0.8px] text-xs disabled:bg-gray-300 bg-white py-2 ring-0`}
              />

              <div className="hs-field-desc" style={{ display: "none" }}></div>
              <div className="actions">
                <button
                  type="submit"
                  disabled={loading || !(isValid && dirty)}
                  className=" flex gap-1 justify-center items-center px-4 py-2 w-full font-manrope tracking-[-0.4px] text-xs text-purple-1500 hover:text-purple-1800 bg-white shrink-0 rounded-4xl font-semibold hover:cursor-pointer"
                >
                  <span>Get started</span>

                  {loading && (
                    <svg
                      class="animate-spin h-5 w-5 text-purple-1500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                </button>
              </div>
            </Form>
            {errors.email ? (
              <small className="text-red-500">*{errors.email}</small>
            ) : null}
          </section>
        );
      }}
    </Formik>
  );
};

export default GlossaryCtaForm;
