import * as React from "react";

const iconTransition = {
  left: "mx-1 group-hover:mr-1.5 group-hover:ml-0.5",
  right: "mx-1 group-hover:ml-1.5 group-hover:mr-0.5",
  bottom: "ml-1 group-hover:mt-0.5 group-hover:-mb-0.5",
  top: "ml-1 group-hover:mb-0.5 group-hover:-mt-0.5",
};

const CustomButton = ({
  link,
  text = "Button",
  buttonClass,
  icon,
  onClick,
  target = "",
  direction,
  isFullWidth,
  disabled = false,
}) => {
  const classes = `${buttonClass} ${
    isFullWidth ? "w-full" : "max-w-max"
  } group flex items-center justify-center py-2 px-4 font-semibold ease-in-out duration-500 rounded-full font-manrope tracking-tight_1 ${
    disabled ? "cursor-not-allowed opacity-50" : ""
  }`;

  const handleLinkClick = (e) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  return link ? (
    <a
      href={link}
      target={target}
      className={classes}
      onClick={handleLinkClick}
    >
      {text}
      {icon && (
        <div
          className={`duration-500 ease-in-out ${iconTransition[direction]}`}
        >
          {icon}
        </div>
      )}
    </a>
  ) : (
    <button
      aria-label="chevron-btn"
      onClick={disabled ? null : onClick}
      className={classes}
      disabled={disabled}
    >
      {text}
      {icon && (
        <div
          className={`duration-500 ease-in-out ${iconTransition[direction]}`}
        >
          {icon}
        </div>
      )}
    </button>
  );
};

export default CustomButton;
