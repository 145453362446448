import { useState, useEffect } from "react";

const useWindowWidth = (threshold = 768) => {
  const [isWide, setIsWide] = useState(false);

  useEffect(() => {
    // Check if window object is available (SSR check)
    if (typeof window !== "undefined") {
      // Define the resize handler function
      const handleResize = () => {
        setIsWide(window.innerWidth > threshold);
      };

      // Set initial width state
      handleResize();

      // Add event listener to resize event
      window.addEventListener("resize", handleResize);

      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [threshold]); // Re-run effect if threshold changes

  return isWide;
};

export default useWindowWidth;
