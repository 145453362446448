import React, { useState } from "react";
import "../../../styles/components/Slices/SectionKahlo.scss";
import { useMediaQuery } from "react-responsive";

const SectionKahlo = ({ slice }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return (
    <section className="relative px-4 mx-auto mr-4 overflow-x-auto border-none sm:mx-12 xl:mx-auto sm:px-0 lg:px-5 max-w-7xl scroll-smooth no-scrollbar">
      <article className="py-10 xl:mx-11  gap-2 xs:gap-2.5 md:gap-6 flex lg:grid grid-cols-3">
        {slice?.items?.map((value, index) => {
          const isHovered = hoveredIndex === index;
          return (
            <div
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              key={index}
              className=" sm:max-w-[368px] group card_scroll_width kahlo_card_bg px-3.5 py-5 sm:px-5 sm:py-7 md:px-[22px] md:py-[22px]"
            >
              <div className="flex gap-2.5 items-start min-h-[56px]">
                {isHovered && isDesktop ? (
                  <img
                    height={24}
                    width={24}
                    src={value?.hovered_icon?.url}
                    alt={value?.hovered_icon?.alt || "Hovered Icon"}
                  />
                ) : !isDesktop ? (
                  <img
                    height={24}
                    width={24}
                    src={value?.hovered_icon?.url}
                    alt={value?.hovered_icon?.alt || "hovered Icon"}
                  />
                ) : (
                  <img
                    height={24}
                    width={24}
                    src={value?.icon?.url}
                    alt={value?.icon?.alt || "Icon"}
                  />
                )}

                <p className="text-lg md:text-gray-2700 text-purple-1100 sm:text-[20px] md:group-hover:text-purple-1100 font-manrope font-semibold tracking-tighter_1">
                  {value?.header_title?.text}
                </p>
              </div>
              <p className="pt-2.5 text-sm md:text-base text-gray-600">
                {value?.descriptor_text?.text}
              </p>
            </div>
          );
        })}
      </article>
    </section>
  );
};

export default SectionKahlo;
